<template>
	<div class="trpay">
		<el-card class="trpay-main">
			<div class="tr-l1">
				<span>改签订单信息</span>
			</div>
			<div class="tr-l2">
				<div>
					<span>城市：</span>
					<span class="padL10">{{orderDetail.newFromCityName}}</span>
					<span class="padL10">—</span>
					<span class="padL10">{{orderDetail.newToCityName}}</span>
				</div>
				<div>
				    <span>城市站点：</span>
				    <span class="padL10">{{orderDetail.newFromStationName}}</span>
				    <span class="padL10">—</span>
					<span class="padL10"> {{orderDetail.newToStationName}}</span>
					
				</div>
				<div>
					<span>出发时间：</span>
					<span class="padL10">{{orderDetail.newFromDate}}</span>
					<span class="padL10">{{orderDetail.newFromTime}}</span>
					<span class="padL10">({{orderDetail.newFromWeek}})</span>
				</div>
				<div>
					<span>改签差价金额：</span>
					<span class="colorzs padL10">￥{{orderDetail.changeAmount}}</span>
				</div>
				<div v-for="(item, index) in orderDetail.psgList" :key="index">
					<span>乘车人信息：</span>
					<span class="padL10">{{item.passengerName}}</span>
					<span class="padL10">(车次 {{orderDetail.newTrainCode}})</span>
					<span class="padL10">{{item.newSeatCode}}</span>
				</div>
			</div>
			
		</el-card>
	</div>
</template>

<script>
	export default{
		name:"TrAlterPay",
		data(){
			return {
				
			}
		},
		props:{
			orderDetail:{
				type:Object,
				default(){
					return {}
				}
			},
		},
		
	}
</script>

<style lang="scss" scoped>
	.trpay{
		.trpay-main{
			text-align: left;
			.tr-l1{
				font-weight: bold;
				font-size: 20px;
			}
			.tr-l2{
				margin-left: 20px;
				font-size: 14px;
				line-height: 30px;
				padding: 10px 0;
				
				.colorzs{
					color: #FF9524;
				}
			}
			
		}
		.padL10{
			padding-left: 10px;
		}
		.padR10{
			padding-right: 10px;
		}
	}
</style>
