
/*
*  订单详情接口配置页面
*
*/ 
var noop = function() {};

/* 
*  pc端【orderFrom = 10503】 各个业务对应的支付场景
*  很怪异没有:  保险订单的业务类型没有支付场景
*  支付场景  业务场景
 * 12701   国内机票订单支付    11001 
 * 12702   国内机票改签单支付    11003
 * 12703   火车票订单支付       11009
 * 12704   火车改签支付         11011
 * 12705   酒店订单支付         11007
 * 12716   用车订单支付         11018
 * 12720   代客泊车订单支付     11014
 * 12723   机场服务订单支付     11012
 * 12726   代办值机订单支付     11019
 * 12728   国际机票订单支付     11004
 * 12729   国际机票改签支付     11006
 * 12734   餐饮订单支付         11024
 * 
 **/ 

   

var params = {
	//机票票正常单
	'11001': {
		//订单详情接口
		conn: 'tkQuery2023.orderDetail3',
		// 支付场景
		payScene:"12701",
		//获取订单详情的参数
		getParams(orderId, orderType) {
			return {
				id: orderId
			}
		},
	},
	// 机票改签单
	'11003': {
		//订单详情接口
		conn: 'tkQuery2023.tkChangeDetail3',
		// 支付场景
		payScene:"12702",
		//获取订单详情的参数
		getParams(orderId, orderType) {
			return {
				id: orderId
			}
		},
	},
	'11004': {
		//订单详情接口
		conn: 'tkQuery2023.orderDetail3',
		// 支付场景
		payScene:"12728",
		//获取订单详情的参数
		getParams(orderId, orderType) {
			return {
				id: orderId
			}
		},
	},
	// 机票改签单
	'11006': {
		//订单详情接口
		conn: 'tkQuery2023.tkChangeDetail3',
		// 支付场景
		payScene:"12702",
		//获取订单详情的参数
		getParams(orderId, orderType) {
			return {
				id: orderId
			}
		},
	},
	//酒店正常单
	'11007': {
		//订单详情接口
		conn: 'hotelOrder.hotelOrderDetail',
		// 支付场景
		payScene:"12705",
		//获取订单详情的参数
		getParams(orderId, orderType) {
			return {
				orderId: orderId
			}
		},
	},
	//火车票正常单
	'11009': {
		//订单详情接口
		conn: 'trBook.trOrderDetail',
		// 支付场景
		payScene:"12703",
		//获取订单详情的参数
		getParams(orderId, orderType) {
			return {
				id: orderId
			}
		},
	},
	//火车票改签详
	'11011': {
		//火车票改签单详情接口
		conn: 'trChange.trChangeOrderDetail',
		// 支付场景
		payScene:"12704",
		//获取改签详情的参数
		getParams(orderId, orderType) {
			return {
				changeId: orderId
			}
		},
	},
	//休息室
	'11012': {
		//订单详情接口
		conn: 'airService.queryServiceOrderDetail',
		// 支付场景
		payScene:"12723",
		//获取订单详情的参数
		getParams(orderId, orderType) {
			return {
				orderId: orderId
			}
		},
	},

	//用车
	'11018': {
		//订单详情接口
		conn: 'carBook.queryCarOrderDetail',
		// 支付场景
		payScene:"12716",
		//获取订单详情的参数
		getParams(orderId, orderType) {
			return {
				orderId: orderId
			}
		},
	},

	//值机
	'11019': {
		//订单详情接口
		conn: 'dbzj.getCheckInDeatils',
		// 支付场景
		payScene:"12726",
		//获取订单详情的参数
		getParams(orderId, orderType) {
			return {
				id: orderId
			}
		},
	},
	//餐饮
	'11024': {
		//订单详情接口
		conn: 'food.cyOrderDetail',
		// 支付场景
		payScene:"12734",
		//获取订单详情的参数
		getParams(orderId, orderType) {
			return {
				orderId: orderId
			}
		},
	},
};

var orderjs = {};

//获取订单详情
orderjs.getOrderDetail = function($conn,orderId, orderType, callback, target) {
	if(!(orderId && orderType)) {
		return;
	}
	
	callback = callback || noop;
	target = target || this;
	var obj = params[orderType];
	var query = $conn.getConn(obj.conn);
	query(obj.getParams(orderId, orderType), res => {
		callback.call(target, res);
	}, (res) => {
		target.$alert(res.tips);
	})
};
//获取关联订单
orderjs.getBxOrderDetailByOrderId = function($conn,orderId, orderType, callback, target) {
	var queryString = {
		glddId: orderId
	};
	
	$conn.getConn('insurance.getBxOrderListByGlddId')(queryString, res => {
		var data = res.data || [];
		callback.call(target, data);
	}, res => {

	})
};
// 通过国内机票订单  获取增值服务订单 tkQuery.getUnionOrder
orderjs.getUnionOrderByTkOrderId = function($conn,orderId, orderType, callback, target) {
	var queryString = {
		orderId: orderId
	};
	$conn.getConn('tkQuery.getUnionOrder')(queryString, (res) => {
		var data = res.data || {};
		callback.call(target, data);
	}, (err) => {
		//处理异常情况未获取支付方式的问题  可能后台的锅
		callback.call(target, {});
	})
};

// 获取当前产品类型的支付场景
orderjs.getPayScene = function(orderType){
	var obj = params[orderType] || {};
	if(obj.payScene){
		return obj.payScene
	}else{
		console.error('请配置支付场景')
	}
	 return obj.payScene
	
}


export default orderjs;