<template>
	<div class="p-main" :style="{'min-height':height+'px'}">
		<div class="main">
			<!-- 支付视图 -->
			<!-- 机票 -->
			<tk-pay v-if="orderType == 11001  || orderType == 11004" :orderDetail="orderDetail"></tk-pay>
			<!-- 机票改签 -->
			<tk-alter-pay v-if=" orderType == 11003" :orderDetail="orderDetail"></tk-alter-pay>
			<!-- 酒店 -->
			<ht-pay v-if="orderType == 11007 || orderType == 11008" :orderDetail="orderDetail"></ht-pay>
			<!--火车票 -->
			<tr-pay v-if="orderType == 11009 || orderType == 11010" :orderDetail="orderDetail" :payAmount="payAmount"></tr-pay>
			<!--火车票改签 -->
			<tr-alter-pay v-if="orderType==11011" :orderDetail="orderDetail"></tr-alter-pay>
			
			
			<!-- 支付科目 -->
			<el-card class="marTop20">
				<div class="flex">
					<div class="textLeft inlineB bold font20">
						需支付<span class="colorzs">￥{{ payAmount}}</span>
						<span v-if="orderType == 11001  || orderType == 11004" class="tk-tips">航班价格变动频繁，请尽快完成支付以确保您的机位和价格</span>
					</div>
					<div >
						<div class="tr-color" v-show="orderType!=11007">
							<span class="padR10">剩余支付时间</span>
							<div class="trbg marL10">{{minutsOut}}</div>
							<span class="trfh">:</span>
							<div class="trbg">{{secondOut}}</div>
						</div>
						<!-- <div class="tr-color" v-if="orderDetail.orderStatus==4 && minutsOut=='00' && secondOut=='00'">
							支付超时
						</div> -->
					</div>
				</div>

				<el-tabs v-model="activeName " :tab-position="'left'" @tab-click="handleClick" class="marTop20">

					<el-tab-pane v-for="payMethodItem in payMethods.list" :key="payMethodItem.payMethod "
						:label="payMethodItem.payMethodName" :name="payMethodItem.payMethod" class="font20" size="20">
						<div class="borderH pad30 h120 textLeft marL10">
							<div v-for="paySubjectItem in payMethodItem.paySubjects" :key=" paySubjectItem.subjectId"
								class="pay-subject-item"
								:class="{'selected':selected.paySubject.subjectId == paySubjectItem.subjectId}"
								@click="selectPayMethod(payMethodItem,paySubjectItem)">
								{{paySubjectItem.subjectName}}
							</div>
						</div>
					</el-tab-pane>

				</el-tabs>
			</el-card>
			<div class="marTop20">
				<!-- -->
				<el-button size="medium" class="btn" :disabled="!(orderDetail.isShowPay==1 || orderDetail.showPayButton==1)" @click="toPay()"
					:loading="!payFlag">
					下一步
				</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import payOrder from './payOrder.js';
	import TkPay from './payComponent/TkPay.vue';
	import TrPay from './payComponent/TrPay.vue';
	import HtPay from './payComponent/HtPay.vue';
	import TkAlterPay from './payComponent/TkAlterPay.vue';
	import TrAlterPay from './payComponent/TrAlterPay.vue'
	import QRCode from 'qrcodejs2';

	export default {
		name: 'Payment',
		components: {
			'tk-pay': TkPay,
			'tr-pay': TrPay,
			'ht-pay': HtPay,
			'tk-alter-pay':TkAlterPay,
			'tr-alter-pay':TrAlterPay,
		},
		data() {
			return {

				onlinePay: false, // 是否是在线支付 false否 true 是
				activeName: '', // 默认显示的支付方式名称
				query: {}, // 路由参数

				// 防止支付连点
				payFlag: true, // true 可以点击， false 不能点击
				isConsent: true, //是否同意
				//订单下单时间
				readyDate: '',
				//支付超时时间
				payTimeOut: 30,
				//剩余多少分支付超时
				minutsOut: '00',
				//剩余多少秒支付超时
				secondOut: '00',

				//订单相关
				orderFrom: "",
				orderType: '',
				orderId: '',
				orderDetail: {},
				orderList: [],
				//需要支付的总金额
				payAmount: 0,
				//支付渠道列表
				channelList: [],
				//支付方式图标列表
				payIconList: {
					//协欠图标样式
					'1210501': {
						className: 'icon-xy icon-pay_pact'
					},
					//预存款
					'1210502': {
						className: 'icon-xy icon-pay_pact'
					},
					//0元支付
					'1211101': {
						className: 'icon-xy icon-mmzf'
					},
					//支付宝图标样式
					'1': {
						className: 'icon-al icon-pay_alipay',
					},
					//微信图标样式
					'2': {
						className: 'icon-wx icon-pay_wechat',
					},
					//银联
					'3': {
						className: 'icon-yl icon-pay_card',
					},

				},
				payMethods: {
					list: [

					],
					selectIndex: 0,
				},
				//当前选择的支付方式和支付科目
				selected: {
					payMethod: {},
					paySubject: {},
				},

				addServiceZongPrice: 0, // 增值服务的总价格
				weixinTimeOut: null, // 微信支付定时器
				
				timestamp:0, //调用接口返回时间戳
			}
		},

		computed: {
			height() {
				let height = document.body.clientHeight;
				return height
			}
		},
		destroyed() {
			this.weixinTimeOut = null;
		},
		mounted() {
			/*
			 *  支付逻辑：
			 *  1.获取路由参数   订单号：orderId，订单类型：orderType，页面来源：type
			 *  2.获取订单详情
			 *  3.根据当前订单业务和支付场景获取支付列表
			 *  4.默认支付方式
			 *  5.选择操作支付方式，提交支付
			 */
			this.query = this.$route.query || {};
			this.orderType = this.query.orderType;
			this.orderId = this.query.orderId;
			this.payTimeOut = (this.$route.query.timeOut || this.$route.query.timeOut==0)?this.$route.query.timeOut: this.payTimeOut;
			// 获取订单详情，以及关联保险。机+x产品订单
			payOrder.getOrderDetail(this.$conn, this.query.orderId, this.query.orderType, this.getOrderDetail, this)
			
			// 特殊处理
			this.importantHandler();

		},
		methods: {
			importantHandler() {
				/**
				 * 在线支付支付，页面回跳到obt，极有可能造成sessionStorage中token无法获取
				 * 因此在选择支付前，将token转到localStorage中
				 * */
				this.$common.localset("tmpaccess", this.$common.sessionget("access_token"));
			},
			// 获取订单详情
			getOrderDetail(res) {
				this.orderDetail = res.data || {};
				this.timestamp = res.timestamp;
				this.readyDate = this.orderDetail.bookDate;
				if(this.orderType == 11003 || this.orderType == 11006 || this.orderType == 11011){
					this.readyDate = this.orderDetail.applyDate;
				}
				// 处理用车订单返回的orderType 不是 用车类型问题  后台的锅
				if (this.orderType == '11018') {
					this.orderDetail.useType = res.data.orderType;
					this.orderDetail.useDays = (res.data.useDays == 0) ? 0.5 : res.data.useDays;
				}
				this.orderDetail.orderType = this.query.orderType;

				//获取关联订单
				payOrder.getBxOrderDetailByOrderId(this.$conn, this.orderId, this.orderType, this.getRelativeOrder, this);
				// payOrder.getBxOrderDetailByOrderId(this.orderId, this.orderType, this.trgldd, this);
				// 支付倒计时 火车票业务30分钟内支付  this.$route.query.timeOut
				this.$common.countdown({
					target: this,
					date: this.timestamp,
					bookDate: this.readyDate,
					timeOut: this.payTimeOut,
					outCallback: function(res) {
					},
					onCallback: function(res) {
						this.secondOut = res.secondOut;
						this.minutsOut = res.minutsOut;
					}
				})
				
				this.getPayAmount();
			},
			// 获取主订单关联的报险订单
			getRelativeOrder(dataList) {
				// 处理保险订单未返回订单类型的问题  后台的锅
				if (dataList.length > 0) {
					for (var i = 0; i < dataList.length; i++) {
						dataList[i].orderType = '11015';
					}
				}
				this.orderList = dataList.concat(this.orderDetail);

				// 如果是机票的增值服务页面跳转过来的，需要获取下机票关联的机+x订单列表，  仅从增值服务直接跳转的 同时支付
				if (this.orderType == '11001' && this.query.type == 'tkAddService') {

					payOrder.getUnionOrderByTkOrderId(this.$conn, this.orderId, this.orderType, this.getAddServiceOrder,
						this);

				} else {
					this.getPaySubject(this.orderList);
				}

			},
			//  获取机票关联的 增值服务：机+x订单列表
			getAddServiceOrder(data) {
				var tkServiceData = data || {};
				// 处理 机+x 未返回总价格的问题
				this.orderDetail.totalPrice = (tkServiceData.totalPrice - 0) || this.orderDetail.totalPrice;
				var glldList = tkServiceData.unionOrderList || [];

				this.orderList = this.orderList.concat(glldList);
				this.getPaySubject(this.orderList);
				this.getPayAmount();
			},
			//  机票重复订单验证  下单后支付前的重复订单验证
			yanZhengSameOrder: function(orderId) {

				this.$conn.getConn('tkBook.querySameOrder')({
					orderIds: orderId
				}, (res) => {
					var data = res.data;
					if (data && data.length > 0) {
						var content = "您已预订过" + (data[0]['departTime'] || '') + ' ' + data[0][
							'departCityName'] + '-' + data[0]['arriveCityName'] + (data[0].flightNo || '') +
							'次航班,' + '本次预订：' + (self.orderDetail['orderRangeBeanList'][0]['departDate'] ||
							'') + ' ' + (self.orderDetail['orderRangeBeanList'][0]['departTime'] || '') + ' ' +
							self.orderDetail['orderRangeBeanList'][0]['departCityName'] + '-' + self
							.orderDetail['orderRangeBeanList'][0]['arriveCityName'] + (self.orderDetail[
								'orderRangeBeanList'][0].flightNo || '') + '次航班,' + '请确认！'
						this.$alert(content, '重复订单提示', {
							confirmButtonText: '确定',
							type: 'warning',
							callback: action => {

							}
						})
					}

				}, (res) => {

				});
			},
			// 计算总金额
			//支付金额   用于底部按钮显示
			getPayAmount() {
				if (this.orderType == 11001 || this.orderType == 11004) { // 机票正常单
					this.payAmount = (this.orderDetail.totalPrice || 0) + (this.orderDetail.bxPrice || 0) + this
						.addServiceZongPrice;
					this.readyDate = this.orderDetail.bookDate;

					if (this.orderType == 11001) {
						this.yanZhengSameOrder(this.orderId);
					}

				} else if (this.orderType == 11003) { // 机票改签单  changeAmount
					this.payAmount = (this.orderDetail.changeAmount - 0);
					this.readyDate = this.orderDetail.applyDate;
				} else if (this.orderType == 11011) { // 火车票改签单
					this.payAmount = this.orderDetail.changeAmount - 0;
					this.readyDate = this.orderDetail.applyDate;
				} else if (this.orderType == 11009) { // 火车票正常单
					this.payAmount = this.orderDetail.ticketPrice + this.orderDetail.sellServiceCharge + (this.orderDetail
						.bxPrice || 0);
					this.readyDate = this.orderDetail.bookDate;
				} else if (this.orderType == 11007) { // 休息室 || 国内酒店正常单
					// 计算可能带浮点数的 金额相加
					this.payAmount = this.orderDetail.cashPayAmt || this.orderDetail.totalPrice  // 酒店totalPrice 已经计算好了服务费
					this.readyDate = this.orderDetail.bookDate;
				} else if (this.orderType == 11012) {
					this.payAmount = this.orderDetail.totalPrice;
					this.readyDate = this.orderDetail.bookDate;
				} else if (this.orderType == 11018) { // 用车
					this.payAmount = this.orderDetail.useVehicleFee;
					this.readyDate = this.orderDetail.bookDate;
				} else if (this.orderType == 11019) { // 值机
					this.payAmount = this.orderDetail.totalPrice;
					this.readyDate = this.orderDetail.bookDate;
				} else if (this.orderType == 11024) { // 餐饮
					this.payAmount = this.orderDetail.payAmount;
					this.readyDate = this.orderDetail.bookDate;
				}
				
			},


			// 获取支付方式列表
			getPaySubject(orderList) {
				var orderBeans = this.getOrderBeans(orderList);
				var query = this.$conn.getConn('pay.getPaySubject');
				query({
					orderBeans: orderBeans, // 订单集合 ,
					payScene: payOrder.getPayScene(this.orderType), // 支付场景代码
				}, res => {
					var data = res.data || {};
					// 重组支付列表数据
					var arr = data.payMethods || [];
					var paySubjects;
					for (var i = 0; i < arr.length; i++) {
						paySubjects = arr[i].paySubjects || [];

						var newPaySubjects = [];
						for (var j = 0; j < paySubjects.length; j++) {
							//  如果满足显示条件了，添加至支付列表
							if (this.isShowPaySubject(paySubjects[j])) {
								newPaySubjects.push(paySubjects[j]);
							}
						}
						arr[i].paySubjects = newPaySubjects;

					}

					this.payMethods.list = arr;

					//处理默认选中问题
					for (var i = 0; i < arr.length; i++) {
						paySubjects = arr[i].paySubjects;
						if (paySubjects && paySubjects.length > 0) {
							this.selectPayMethod(arr[i], paySubjects[0], false);
							break;
						}
					}
				}, res => {
					mui.alert(res.tips);
				})
			},

			//获取要支付的订单集合:查支付科目用
			getOrderBeans(orderList) {
				var orderBeans = [];
				if (Object.prototype.toString.call(orderList) == "[object Array]") {
					for (var i = 0; i < orderList.length; i++) {
						orderBeans.push({
							orderId: orderList[i].changeId || orderList[i].orderId || orderList[i].id, //订单id ,
							orderType: orderList[i].orderType, //订单类型
						});
					}
				}
				return orderBeans;
			},
			// 是否显示支付科目   返回值为 boolean
			isShowPaySubject(payItem) {
				var bool = true;
				// 某些条件下的判断
				// if(){bool = false}
				return bool;
			},
			handleClick(tab, event) {

				var name = tab.name;
				this.payMethods.list.forEach((payMethodItem) => {
					if (payMethodItem.payMethod == name) {
						this.selectPayMethod(payMethodItem, payMethodItem.paySubjects[0])
					}
				})
			},

			//选择支付方式 click=> 是不是点击支付方式过来的
			selectPayMethod(payMethod, paySubject, click = true) {

				this.selected.payMethod = payMethod;
				this.selected.paySubject = paySubject;
				// 标记当前选中的方式
				this.activeName = payMethod.payMethod;
				//   如果公司支付，并且是超标了
				if (this.selected.payMethod.payMethod == 12105 && this.selected.paySubject.disable) { // 公司支付
					this.payFlag = false;
					var list = this.payMethods.list[1];
					if (!click && list) {
						this.selectPayMethod(list, list.paySubjects[0], false);
					} else {
						this.$message.error('根据贵司差旅管控，超出标准需个人自付');
					}
					return;
				} else {
					this.payFlag = true;
				}
				
        // 增加酒店获取服务费 国内酒店正常单11007 国际酒店正常单11022
				if (this.orderType == 11001 || this.orderType == 11004 || this.orderType == 11009 || this.orderType == 11003 || 
						this.orderType == 11006 || this.orderType == 11007 || this.orderType == 11022) {

					this.payFlag = false;
					if (this.selected.payMethod.payMethod == 12105) { // 公司支付
						this.changeCorpFeeByOnlinePay(this.orderId, false, this.orderType)
					} else {
						this.changeCorpFeeByOnlinePay(this.orderId, true, this.orderType)
					}
				}

			},


			// 修改服务费  用于机票、已加火车票

			changeCorpFeeByOnlinePay(orderId, bolean, orderType) {

				var queryString = {
					orderId: orderId,
					onlinePay: bolean, // 布尔值
					serviceType: orderType,
					version: this.orderDetail.version,
				};
				this.$conn.getConn("pay.modifyServiceFee")(queryString, (resp) => {

					this.payFlag = true;

					var data = resp.data || {};
					//					this.serviceFeeDiff = data.serviceFeeDiff || 0;   // 暂时不用
					var ticketPrice = data.ticketPrice || 0;

					this.orderPriceZong = ticketPrice;

					this.orderDetail.totalPrice = ticketPrice;
					
					// 非酒店
					var bool = this.orderType != 11007 && this.orderType != 11022,
					// 酒店不存在个人自付金额
					    bool2 = (this.orderType == 11007 || this.orderType == 11022) && !this.orderDetail.cashPayAmt;
					
					if (bool || bool2) {
					  this.payAmount = ticketPrice + this.addServiceZongPrice + (this.orderDetail.bxPrice || 0);
					}
				}, () => {
					this.payFlag = true;
				});
			},


			// 下一步 支付
			toPay() {
				if (this.payFlag) {
					this.payFlag = false;
					var param = this.getPayParam();
					if (!(param.payMethod && param.paySubject)) {
						this.$alert('请选择支付科目!若无可选择支付方式，请联系管理员设置！');
						return;
					}

					var topay = this.$conn.getConn('pay.onlinePay');
					var that = this;
					topay(param, (resp) => {
						var data = resp.data || {};
						var payOrderStr = data.reMsg;

						var payMethod = this.selected.payMethod.payMethod || '';
						var paySubject = this.selected.paySubject.subjectId || '';
						var platform = this.selected.paySubject.platform || '';
						//  12104 在线支付       12105协议欠款 公司支付  12109 支付宝预授权支付
						if (payMethod == '12105') {
							this.paySucc(this.orderType);
						} else if (payMethod == '12104') { // 在线支付 12104  // 10. 支付宝   20. 微信h5 21. 微信app 30. 银联
							if (platform >= 10 && platform < 20) { // 支付宝
								this.h5PayForForm(payOrderStr)
							} else if (platform >= 20 && platform < 30) { // 微信扫码
								this.weiXinScanPay(data, param)
							} else if (platform >= 30 && platform < 40) { // 银联支付
								this.h5PayForForm(payOrderStr)
							} else {
								console.error('请配置其他方式')
							}
						} else { // 其他模式
							this.h5PayForForm(payOrderStr)
						}

					}, (err) => {
						this.$alert(err.tips)
					})
				}
			},
			/*
			 *
			 *  h5表单支付 
			 *  用于支付宝支付、银联支付
			 */

			h5PayForForm(orderStr) {
				// 创建一个支付div;
				var el = document.getElementById('pay_no_replay');
				if (el) {
					el.parentNode.removeChild(el);
					el = null;
				}
				var div = document.createElement('div');
				div.setAttribute('id', 'pay_no_replay');
				document.body.appendChild(div);

				this.$nextTick(function() {
					if (orderStr) {
						div.innerHTML = orderStr;
						var forms = document.querySelectorAll("#pay_no_replay  form");
						forms[0].submit();
					};
				});
			},
			// 微信扫码支付
			weiXinScanPay(data, param) {
				this.weixinTimeOut = null;
				var self = this;
				var orderInfo = param["orderList"][0];
				var ops = {
					param: {}
				};
				ops.param.qrCode = data.reMsg; //"weixin://wxpay/bizpayurl?pr=k3x567f";
				ops.param.returnUrl = data.returnUrl; //微信扫码支付成功之后，跳转路径
				ops.param.orderType = orderInfo.orderType;
				ops.param.orderId = orderInfo.orderId;
				var winxinStrDom = `
					<div >
							<div  style="color: #FF9524;font-size:20px;margin-bottom:20px">￥${this.payAmount} <span style="float:right;color:red" id="wx-pay-qrCode-timer"></span>	</div>
							<div id="wx-pay-qrCode" style="display:flex;justify-content: center;"></div>
					</div>
				`;
				// 打开弹窗
				this.$alert(winxinStrDom, '微信扫码支付', {
					dangerouslyUseHTMLString: true,
					callback: () => {
						
						clearInterval(self.weixinTimeOut);
						self.weixinTimeOut = null;
						var payWeiXinElTwo = document.getElementById('wx-pay-qrCode');
						if (payWeiXinElTwo) {
							payWeiXinElTwo.innerHTML = "";
						
							this.payFlag = false;
						}

					}

				});

				this.$nextTick(() => {
					// 加载微信二维码
					var payWeiXinEl = document.getElementById('wx-pay-qrCode');
					payWeiXinEl.innerHTML = null;
					var qrcode = new QRCode(payWeiXinEl, {
						text: ops.param.qrCode,
			 		width: 180,
						height: 180,
						colorDark: '#000000',
						colorLight: '#ffffff',
						correctLevel: QRCode.CorrectLevel.H
					});
					// 加载定时器


					var timers = function() {
						var payWeiXinTimerEl = document.getElementById('wx-pay-qrCode-timer');
						payWeiXinTimerEl.innerHTML  = null;
						var min = 5,
							sec = 59,
							el = payWeiXinTimerEl,
							ajaxing = false;

						var queryOnlinePayStatus = self.$conn.getConn("pay.queryOnlinePayStatus", {
							"beforeFun": () => {
								ajaxing = true;
							},
							"thenBefore": () => {
								ajaxing = false;
							}
						});

						var qparam = {};
						qparam.orderType = self.orderType;
						qparam.orderId = self.orderId;
						
						el.innerHTML = "0" + min + ":" + (sec < 10 ? "0" + sec : sec);


						self.weixinTimeOut = setInterval(function(){
							sec--; //秒减一
							if (sec < 0) {
								min--;
								sec = 59;
							}
							if (min < 0) {
								el.innerHTML = ("支付超时！");
								self.weixinTimeOut = null;
								clearInterval(self.weixinTimeOut);
							} else {
								el.innerHTML = ("0" + min + ":" + (sec < 10 ? "0" + sec : sec));
								if (!ajaxing) { //如果不处于ajax状态中
									queryOnlinePayStatus(qparam, (resp) => {
										var data = resp.data || {};
										if (data.paid == 1) { //已经支付
											
											clearInterval(self.weixinTimeOut);
											self.weixinTimeOut = null;
											// 跳转订单详情页
											// this.$router.resolve()
											window.top.location.href = ops.param.returnUrl;
										} else { //未支付

										}
									});
								}
							}
						}, 1000);
					}
					timers();

				})





			},
			// 支付成功后跳转
			//公司支付：支付成功的回调
			paySucc(orderType) {

				// 暂时放这里，等支付成功页面样式有了去哪个页面处理
				if (this.selected.payMethod.payMethod != '12104') {

					this.$router.push({
						//						path: paySuccPathObj[orderType]['path'],
						path: "/yjDetail/paySuccess",
						query: {
							orderId: this.orderId,
							orderType: orderType,
							onlinePay: 'unline',
							pageFrom: 'paymentList'
						}
					});

				} else {
					this.$router.push({
						path: "/yjDetail/paySuccess",
						query: {
							orderId: this.orderId,
							orderType: orderType,
							pageFrom: 'paymentList'
						}
					});
				}

			},


			//获取支付参数
			getPayParam() {
				var orderList = this.getPayOrderList();
				var payPramObj = {
					orderList: orderList, //订单列表 ,
					payMethod: this.selected.payMethod.payMethod, // 支付方式id ,
					payScene: payOrder.getPayScene(this.orderType), // 支付场景代码 ,
					paySubject: this.selected.paySubject.subjectId, // 支付科目id ,
					//webHost:'',// 安卓、ios客户调用支付时，虚拟的访问网站域名。客户端必传
				};


				return payPramObj;
			},
			//获取要支付的订单的集合:支付参数用
			getPayOrderList() {

				var orderList = [];
				for (var i = 0; i < this.orderList.length; i++) {
					var order = this.orderList[i],
						item = {};

					item['orderType'] = order.orderType;

					if (order.orderType == 11001 || order.orderType == 11004) { // 机票正常单

						item['orderId'] = this.orderId;

						item['payAmount'] = order.totalPrice || 0;
						item['payRemark'] = order.voyageZh + ' ' + order['orderRangeBeanList'][0]['departDate'];
					} else if (order.orderType == 11003 || order.orderType == 11006) { // 机票改签单
						item['orderId'] = this.orderId;
						item['payAmount'] = order.changeAmount || 0;
						item['payRemark'] = order.voyageZh + ' ' + order['rangeList'][0]['nDepartDate'];
					} else if (order.orderType == 11009) { // 火车票正常单
						item['orderId'] = this.orderId;
						//ghr让改的
						item['payAmount'] = this.orderDetail.totalPrice || ((order.ticketPrice || 0) + (order
							.sellServiceCharge || 0));
						item['payRemark'] = order.fromStationName + '-' + order.toStationName + ' ' + order['fromDate'] +
							' ' + order.trainCode + ' ' + order.seatTypeName;
					} else if (order.orderType == 11011) { // 火车票改签单
						item['orderId'] = this.orderId;
						item['payAmount'] = order.changeAmount - 0;
						item['payRemark'] = order.newFromStationName + '-' + order.newToStationName + ' ' + order[
							'newFromDate'] + ' ' + order.newTrainCode + ' ' + order.newSeatTypeName;
					} else if (order.orderType == 11015) { // 保险订单
						item['orderId'] = order.id || '';
						item['payAmount'] = order.xsj || 0;
						item['payRemark'] = '保险价格:' + order.xsj;

					} else if (order.orderType == 11012) { // 机场服务
						item['orderId'] = this.orderId;
						item['payAmount'] = order.totalPrice - 0;
						item['payRemark'] = '机场服务' + order.totalPrice - 0;
					} else if (order.orderType == 11007) { // 国内酒店正常单
						item['orderId'] = this.orderId;
						item['payAmount'] = this.payAmount - 0;
						item['payRemark'] = '酒店费用' + this.payAmount - 0;
					} else if (order.orderType == 11018) { // 用车服务
						item['orderId'] = this.orderId;
						item['payAmount'] = order.useVehicleFee - 0;
						item['payRemark'] = '用车' + order.useVehicleFee;
					} else if (order.orderType == 11019) { // 值机
						item['orderId'] = this.orderId;
						item['payAmount'] = order.totalPrice - 0;
						item['payRemark'] = '值机' + order.totalPrice;
					} else if (order.orderType == 11024) {
						item['orderId'] = this.orderId;
						item['payAmount'] = order.payAmount - 0;
						item['payRemark'] = '餐饮' + order.payAmount - 0;
					} else { // 如果保险订单没有orderType，
						this.$message('请阅读支付配置须知，自行配置！')

					}

					orderList.push(item);
				};

				return orderList;
			},


		}
	}
</script>

<style lang="scss" scoped>
	.p-main {
		background-color: #EEF1F6;
		.tk-tips{
			text-align: left;
			color: #FF9524;
			font-size: 12px;
			margin-left: 20px;
			font-weight: 300;
		}
		.main {
			width: 1200px;
			margin: 0 auto;
			padding-top: 20px;
		}

		.marTop20 {
			margin-top: 20px;
		}

		.marL10 {
			margin-left: 10px;
		}

		.textLeft {
			text-align: left;
		}

		.flex {
			display: flex;
			justify-content: space-between;
		}

		.font20 {
			font-size: 20px;
		}

		.font16 {
			font-size: 16px;
		}

		.bold {
			font-weight: bold;
		}

		.colorzs {
			color: #FF9524;
		}

		.colorB {
			color: #BBBBBB;
		}

		.inlineB {
			display: inline-block;
		}

		.lineH24 {
			line-height: 24px;
		}

		.padL5 {
			padding-left: 5px;
		}

		.pad30 {
			padding: 30px;
		}

		.h120 {
			height: 120px;
		}

		.borderH {
			border: 1px solid lightgray;
		}

		.borderL {
			border: 1px solid blue;
		}

		.btn {
			padding: 10px 30px;
			font-size: 15px;
		}

		/deep/ .el-tabs__item {
			padding: 0 20px 0 0;
			font-size: 16px;
			line-height: 60px;
			height: 60px;
		}

		.pay-subject-item {
			text-align: center;
			display: inline-block;
			margin-right: 30px;
			width: 200px;
			height: 40px;
			font-size: 16px;
			line-height: 40px;
			border-radius: 4px;
			border: 1px solid #c0c4cc;
			cursor: pointer;
		}

		.pay-subject-item.selected {
			border-color: #409EFF;
			border-width: 2px;
			color: #FFFFFF;
			background-color: #409EFF;
			// background-color: rgba(64,160,255,0.2);
		}

		.tr-color {
			display: flex;
			font-size: 16px;
			line-height: 32px;
			font-weight: 100;

			.trbg {
				background-color: #00A7FA;
				color: #FFFFFF;
				width: 32px;
				border-radius: 4px;
				text-align: center;
			}

			.trfh {
				color: #00A7FA;
				margin: 0 3px;
			}

		}

	}
</style>
