<template>
	<div class="ptp">
		<el-card>
			<p class="tp-l1">
				订单信息
			</p>
			<div class="tp-l2">
				<div v-for="rangeItem in orderRangeBeanList" :key="rangeItem.id" class="line-h30">
					<div class="line-h40">
						<span>机票：</span>
						<span>{{rangeItem.departCityName}}</span>
						<span> — </span>
						<span>{{rangeItem.arriveCityName}}</span>
					</div>
					<div class="line-h40">
					    <span>机场：{{rangeItem.departAirportName}}{{rangeItem.departTerminal}} — {{rangeItem.arriveAirportName}}{{rangeItem.arriveTerminal}}</span>
					</div>
					<div class="line-h40">
						<span>出发时间：{{rangeItem.departDate}} {{rangeItem.departTime}} ({{rangeItem.week}})</span>
					</div>
				
				</div>
				<div class="line-h40">
					<span>乘机人：{{orderDetail.psgName}}</span>
				</div>
				<div class="line-h40">
					订单金额：<span class="colorzs">{{'￥'+orderDetail.totalPrice}}</span>
				</div>
				<div class="line-h40">
					保险金额：<span class="colorzs">{{'￥'+orderDetail.bxPrice}}</span>
				</div>
			</div>
			
			
		</el-card>
	</div>
</template>

<script>
	export default{
		name:"TkPay",
		data(){
			return {
				
			}
		},
		props:{
			orderDetail:{
				type:[Object],
				default(){
					return {}
				}
			}
		},
		computed:{
			// 订单航段信息
			orderRangeBeanList(){
				return this.orderDetail.orderRangeBeanList || []
			},
			// 乘机人信息
			orderPsgBeanList(){
				return this.orderDetail.orderPsgBeanList || []
			}
		}
		
	}
</script>

<style lang="scss" scoped>
	.ptp{
		
		.tp-l1{
			font-weight: bold;
			font-size: 20px;
			text-align: left;
			
		}
		.tp-l2{
			margin-left: 20px;
			font-size: 14px;
			padding: 10px 0;
			text-align: left;
			.line-h30{
				line-height: 30px;
			}
			.colorzs{
				color: #FF9524;
			}
		}
		.line-h40{
			line-height: 40px;
		}
		
	}
</style>
