<template>
	<div class="htpay">
		<el-card class="htpay-main">
			<div class="ht-l1">
				<span>订单信息</span>
			</div>
			<div class="ht-l2">
				<div>
					入住人：<span class="padL10">{{orderDetail.rzrxm}}</span>
				</div>
				<div>
					酒店名称：<span class="padL10">{{orderDetail.jdmc}}（{{orderDetail.cityName}}）{{orderDetail.fxmc}} {{orderDetail.rooms}} 间 {{orderDetail.nights}}晚</span>			
				</div>
				<div>
					入住日期：<span class="padL10">{{orderDetail.rzrq}}</span>
				</div>
                <div>
					离店日期：<span class="padL10">{{orderDetail.ldrq}}</span>
				</div>
				<div v-if="orderDetail.cashPayAmt">
					企业支付：<span class="colorzs padL10">{{'￥'+(orderDetail.totalPrice - orderDetail.cashPayAmt)}}(企业账户自动扣除)</span>
				</div>
				<div v-if="orderDetail.cashPayAmt">
					个人自付：<span class="colorzs padL10">{{'￥'+orderDetail.cashPayAmt}}</span>
				</div>
				<div>
					订单金额：<span class="colorzs padL10">{{'￥'+orderDetail.totalPrice}}</span>
				</div>
			</div>
			
		</el-card>
	</div>
</template>

<script>
	export default{
		name: 'HtPay',
		props:{
			orderDetail:{
				type:Object,
				default(){
					return {}
				}
			}
		},
	}
</script>

<style lang="scss" scoped>
	.htpay{
		.htpay-main{
			text-align: left;
			.ht-l1{
				font-weight: bold;
				font-size: 20px;
			}
			.ht-l2{
				margin-left: 20px;
				font-size: 14px;
				line-height: 30px;
				padding: 10px 0;
				
				.colorzs{
					color: #FF9524;
				}
			}
			
		}
		.padL10{
			padding-left: 10px;
		}
		.padR10{
			padding-right: 10px;
		}
	}
</style>
