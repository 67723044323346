<template>
	<div class="ptp">
		<el-card>
			<p class="tp-l1">
				订单信息
			</p>
			<div class="tp-l2">
				<div v-for="rangeItem in rangeList" :key="rangeItem.id" class="line-h30">
					<div class="line-h30">
						<span>机票：</span>
						<span>{{rangeItem.nDepartCityName}}</span>
						<span> — </span>
						<span>{{rangeItem.nArriveCityName}}</span>
					</div>
					<div class="line-h30">
					    <span>机场：{{rangeItem.nDepartAirportName}}{{rangeItem.nDepartTerminal}} — {{rangeItem.nArriveAirportName}}{{rangeItem.nArriveTerminal}}</span>
					</div>
					<div class="line-h30">
						<span>出发时间：{{rangeItem.nDepartDate}} {{rangeItem.nDepartTime}} ({{rangeItem.nDepartWeek}})</span>
					</div>
					
				</div>
				<div class="line-h30">
					<span>乘机人：{{psgName}}</span>
				</div>
				<div class="line-h30">
					改签差价金额：<span class="colorzs">{{'￥'+orderDetail.changeAmount}}</span>
				</div>
			
			</div>
			
			
		</el-card>
	</div>
</template>

<script>
	export default{
		name:"TkAlterPay",
		data(){
			return {
				
			}
		},
		props:{
			orderDetail:{
				type:[Object],
				default(){
					return {}
				}
			}
		},
		computed:{
			// 订单航段信息
			rangeList(){
				return this.orderDetail.rangeList || []
			},
			// 乘机人信息
			psgList(){
				return this.orderDetail.psgList || []
			},
			psgName(){
				return this.psgList.map(psgItem=>{
					return psgItem.psg
				}).join('、')
			}
		}
		
	}
</script>

<style lang="scss" scoped>
	.ptp{
		
		.tp-l1{
			font-weight: bold;
			font-size: 20px;
			text-align: left;
			
		}
		.tp-l2{
			margin-left: 20px;
			font-size: 14px;
			padding: 10px 0;
			text-align: left;
			.line-h30{
				line-height: 30px;
			}
			.colorzs{
				color: #FF9524;
			}
		}
		.line-h30{
			line-height: 30px;
		}
		
	}
</style>
